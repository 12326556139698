@import url(https://fonts.googleapis.com/css?family=Muli:400,700);
@font-face {
  font-family: 'Avenir';
  src: url(/static/media/AvenirLTStd-Medium.4f995fa4.otf) format('otf');
  font-weight: normal;
  font-style: normal;
}

* {
  font-family: Avenir, 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

html {
  height: 100vh;
  font-family: 'Muli', sans-serif;
}

body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
  font-family: 'Muli', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  min-height: 100vh;
  display: flex;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
